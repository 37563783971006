import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class ApplicationService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/application';
    }

    paginate(data = {}, index = null, applicantId) {
        let url = `${this.#api}/user/${applicantId}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    paginateByAgent(data = {}, index = null, agentId) {
        let url = `${this.#api}/${agentId}/by-agent/paginate`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    search(data = {}, index = null) {
        let url = `${this.#api}/search/applications`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    markAsViewed(applicationId) {
        let url = `${this.#api}/${applicationId}/mark-as-viewed`;
        return apiService.post(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`;
        return apiService.put(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    getLatest(id) {
        let url = `${this.#api}/user/${id}/get-latest`
        return apiService.get(url)
    }

    getChangeLog(applicationId){
        let url = `${this.#api}/${applicationId}/get-change-log`
        return apiService.get(url)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getHistories(applicationId) {
        let url = `${this.#api}/${applicationId}/get-history`;
        return apiService.get(url);
    }

    createHistory(data, applicationId) {
        let url = `${this.#api}/${applicationId}/history`;
        return apiService.post(url, data);
    }

    getRequiredDocumentList(applicationId) {
        let url = `${this.#api}/${applicationId}/required-document/list`;
        return apiService.get(url);
    }

    getAllApplication(userId) {
        let url = `${this.#api}/${userId}/get/all`;
        return apiService.get(url);
    }
    getApplicationCount() {
        let url = `${this.#api}/get/count`;
        return apiService.get(url);
    }

    updateApplicationStatus(applicationId, data){
        let url = `${this.#api}/${applicationId}/update-application/status`;
        return apiService.post(url, data)
    }

    getSubmittedApplication(userId){
        let url = `${this.#api}/user/${userId}/submitted-application`;
        return apiService.get(url)
    }

    filterApplications(data = {}){
        let url = `${this.#api}/filter/applications`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
}