import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {RESET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import CKEditor from "ckeditor4-vue";
import GeneralMixin from "@/mixins/GeneralMixin";
import "@/core/plugins/apexcharts";
import VueHtml2pdf from 'vue-html2pdf'

Vue.use(VueHtml2pdf)
Vue.use(require('vue-moment'));
Vue.use(CKEditor);
Vue.config.productionTip = false;
import VueClipboard from 'vue-clipboard2'
import Verte from 'verte';
import 'verte/dist/verte.css';

Vue.component(Verte.name, Verte);
Vue.use(Verte);
Vue.use(VueClipboard)
import DatetimePicker from 'vuetify-datetime-picker'
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

Vue.use(DatetimePicker)
Vue.use(GeneralMixin)
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";

window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";

window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import Snotify from 'vue-snotify';
import Vuelidate from "vuelidate/src";
import VueConfirmDialog from 'vue-confirm-dialog'
import VueBus from 'vue-bus';
// import RouterTab and styles
import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'

// API service init
ApiService.init();

Vue.use(RouterTab)
Vue.use(GeneralMixin)
Vue.use(Snotify);
Vue.use(VueConfirmDialog);
Vue.use(Vuelidate);
Vue.use(VueBus);

router.beforeEach((to, from, next) => {

    // Ensure we checked auth before each page load.
    if (to.name != 'login' && to.name != 'logout' && to.name != 'register' && to.name != 'reset-password' && to.name != 'verification' && to.name != 'forgot-password') {
        Promise.all([store.dispatch(VERIFY_AUTH, to)]).then(next);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
        if (!store.state.UserPermission.permissions.length)
            store.dispatch('GET_SITE_SETTING');
            store.dispatch('GET_USER_PERMISSIONS');
            store.dispatch('GET_APPLICATION_COUNT');
            store.dispatch('GET_APPLICATION_UPDATE_REQUEST_COUNT');
    } else {
        Promise.all([to]).then(next);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }

    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});


if (!window.localStorage.getItem('XSRF-TOKEN')) {
    Vue.axios.get("/sanctum/csrf-cookie").then(() => {

    });
}


new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount("#app");
