import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CityService  {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/city';
    }


    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url, param);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    get(id) {
        let url = `${this.#api}/${id}/details`
        return apiService.get(url)
    }


    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    all(stateId){
        let url = `${this.#api}/get/${stateId}/all`;
        return apiService.get(url)
    }

    import(stateId, data){
        let url = `${this.#api}/get/${stateId}/import`;
        return apiService.post(url, data);
    }
}
