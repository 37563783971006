import RoleCustomPermission from "@/services/user/role/RoleCustomPermission";

const roleCustomService = new RoleCustomPermission();
const UserPermission = {

    state: {
        permissions: [],
    },
    getters: {
        getPermissions(state) {
            return state.permissions;
        },
    },
    actions: {
        GET_USER_PERMISSIONS: (context, payload) => {
            if(localStorage.getItem('conf')!='undefined'  && localStorage.getItem('conf')!=null  && localStorage.getItem('conf')!=''){
                let config = JSON.parse(localStorage.getItem('conf'));
                if (config && Object.keys(config).length > 0) {
                    context.commit('SET_USER_PERMISSIONS', config);
                }
            }
            else {
                roleCustomService.userPermissionList().then(response => {
                        localStorage.setItem('conf', JSON.stringify(response.data.permissions))
                        context.commit('SET_USER_PERMISSIONS', response.data.permissions);
                    }
                ).catch(error => {
                    localStorage.removeItem('conf')
                })
            }
        }
    },
    mutations: {
        SET_USER_PERMISSIONS: (state, payload) => {
            state.permissions = payload
        },
    }
    ,
}
export default UserPermission;