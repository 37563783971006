export default [
    {
        path: "user/:id/role",
        name: "user-role",
        meta: {
            title: 'User Role',
            keepAlive: 'false',
            closable: true // is tab closable, defaults to `true`
        },
        component: () => import("@/view/pages/view/admin-user/role/ManageUserRole.vue")

    },
    {
        path: "role",
        name: "role",
        meta: {
            title: 'Role',
            keepAlive: false,
            closable: true // is tab closable, defaults to `true`
        },
        component: () => import("@/view/pages/view/admin-user/role/Index.vue")

    },
    {
        path: "role/:id/permission",
        name: "role-permission",
        meta: {

            title: 'Role Permission',
            keepAlive: false,
            closable: true // is tab closable, defaults to `true`
        },
        component: () => import("@/view/pages/view/admin-user/permission/ManageRolePermission.vue")

    },
    {
        path: "role/:id/custom-permission",
        name: "user-custom-permission",
        meta: {

            title: 'Role Custom Permission',
            keepAlive: false,
            closable: true // is tab closable, defaults to `true`
        },
        component: () => import("@/view/pages/view/admin-user/permission/ManageRoleCustomPermission.vue")

    },
    {
        path: "permission",
        name: "permission-list",
        meta: {

            title: ' Permission List',
            keepAlive: false,
            closable: true // is tab closable, defaults to `true`
        },
        component: () => import("@/view/pages/view/admin-user/permission/Index.vue")

    },
    {
        path: "subscription",
        name: "subscription",
        meta: {
            title: ' Subscription',
            keepAlive: false,
            closable: true // is tab closable, defaults to `true`
        },
        component: () => import("@/view/pages/view/subscription/Index.vue")
    },
    {
        path: "subscription/create",
        name: "subscription-create",
        meta: {
            title: ' Create Subscription',
            keepAlive: false,
            closable: true // is tab closable, defaults to `true`
        },
        component: () => import("@/view/pages/view/subscription/CreateAndUpdate.vue")
    },
    {
        path: "subscription/:id/update",
        name: "subscription-update",
        meta: {
            title: 'Edit Subscription',
            keepAlive: false,
            closable: true // is tab closable, defaults to `true`
        },
        component: () => import("@/view/pages/view/subscription/CreateAndUpdate.vue")
    },
    {
        path: "notification/all",
        name: "notification-all",
        meta: {
            title: 'Notification',
            icon: 'fas fa-bell',
        },
        component: () => import("@/view/pages/view/admin-user/notification/Index")

    },
]