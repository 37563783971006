import ApplicationService from "@/services/application/ApplicationService";
import DashboardService from "@/services/dashboard/DashboardService";

const applicationService = new ApplicationService();
const dashboard = new DashboardService();

const stats = {
    state: {
        academic_application_open_count: 0,
        academic_application_not_open_count: 0,
        academic_application_draft_count: 0,
        academic_application_submitted_count: 0,
        academic_application_total_submitted_count: 0,
        academic_application_valid_offers_count: 0,
        academic_application_offers_count: 0,
        academic_application_deferred_count: 0,
        academic_application_refund_count: 0,
        academic_application_archived_count: 0,
        academic_application_visa_ready_count: 0,
        academic_application_visa_granted_count: 0,
        academic_application_pending_payments_count: 0,
        visa_application_count: 0,
        academic_application_count: 0,
        total_applicants: 0,
        application_deferred: 0,
        application_refund: 0,
        agent_change_request: 0,
        notes: 0,
        online_inquiry: 0,
    },
    getters: {
        getAcademicApplicationOpen(state) {
            return state.academic_application_open_count;
        },
        getAcademicApplicationNotOpen(state) {
            return state.academic_application_not_open_count;
        },
        getAcademicApplicationDraft(state) {
            return state.academic_application_draft_count;
        },
        getAcademicApplicationTotalSubmit(state) {
            return state.academic_application_total_submitted_count;
        },
        getAcademicApplicationSubmit(state) {
            return state.academic_application_submitted_count;
        },
        getAcademicApplicationValidOffers(state) {
            return state.academic_application_valid_offers_count;
        },
        getAcademicApplicationOffers(state) {
            return state.academic_application_offers_count;
        },
        getAcademicApplicationDeferred(state) {
            return state.academic_application_deferred_count;
        },
        getAcademicApplicationRefund(state) {
            return state.academic_application_refund_count;
        },
        getAcademicApplicationArchived(state) {
            return state.academic_application_archived_count;
        },
        getAcademicApplicationVisaReady(state) {
            return state.academic_application_visa_ready_count;
        },
        getAcademicApplicationVisaGranted(state) {
            return state.academic_application_visa_granted_count;
        },
        getAcademicApplicationPendingPayments(state) {
            return state.academic_application_pending_payments_count;
        },
        getVisaApplicationCount(state) {
            return state.visa_application_count;
        },
        getTotalApplicants(state) {
            return state.total_applicants;
        },
        getTotalApplications(state) {
            return (parseInt(state.visa_application_count) + parseInt(state.academic_application_open_count) + parseInt(state.academic_application_not_open_count))
        },
        getApplicationDeferred(state) {
            return state.application_deferred;
        },
        getApplicationRefund(state) {
            return state.application_refund;
        },
        getAgentChangeRequest(state) {
            return state.agent_change_request;
        },
        getNotes(state) {
            return state.notes;
        },
        getOnlineInquiry(state) {
            return state.online_inquiry;
        }

    },
    mutations: {
        SET_VISA_APPLICATION_COUNT: (state, payload) => {
            state.visa_application_count = payload
        },
        SET_ACADEMIC_APPLICATION_COUNT: (state, payload) => {
            state.academic_application_count = payload
        },
        SET_ACADEMIC_APPLICATION_OPEN_COUNT: (state, payload) => {
            state.academic_application_open_count = payload
        },
        SET_ACADEMIC_APPLICATION_NOT_OPEN_COUNT: (state, payload) => {
            state.academic_application_not_open_count = payload
        },
        SET_ACADEMIC_APPLICATION_DRAFT_COUNT: (state, payload) => {
            state.academic_application_draft_count = payload
        },
        SET_ACADEMIC_APPLICATION_TOTAL_SUBMIT_COUNT: (state, payload) => {
            state.academic_application_total_submitted_count = payload
        },
        SET_ACADEMIC_APPLICATION_SUBMIT_COUNT: (state, payload) => {
            state.academic_application_submitted_count = payload
        },
        SET_ACADEMIC_APPLICATION_VALID_OFFERS_COUNT: (state, payload) => {
            state.academic_application_valid_offers_count = payload
        },
        SET_ACADEMIC_APPLICATION_OFFERS_COUNT: (state, payload) => {
            state.academic_application_offers_count = payload
        },
        SET_ACADEMIC_APPLICATION_DEFERRED_COUNT: (state, payload) => {
            state.academic_application_deferred_count = payload
        },
        SET_ACADEMIC_APPLICATION_REFUND_COUNT: (state, payload) => {
            state.academic_application_refund_count = payload
        },
        SET_ACADEMIC_APPLICATION_ARCHIVED_COUNT: (state, payload) => {
            state.academic_application_archived_count = payload
        },
        SET_ACADEMIC_APPLICATION_VISA_READY_COUNT: (state, payload) => {
            state.academic_application_visa_ready_count = payload
        },
        SET_ACADEMIC_APPLICATION_VISA_GRANTED_COUNT: (state, payload) => {
            state.academic_application_visa_granted_count = payload
        },
        SET_ACADEMIC_APPLICATION_PENDING_PAYMENTS_COUNT: (state, payload) => {
            state.academic_application_pending_payments_count = payload
        },
        SET_TOTAL_APPLICANTS: (state, payload) => {
            state.total_applicants = payload
        },
        SET_UPDATE_REQUEST_APPLICATION_DEFERRED: (state, payload) => {
            state.application_deferred = payload
        },
        SET_UPDATE_REQUEST_APPLICATION_REFUND: (state, payload) => {
            state.application_refund = payload
        },
        SET_AGENT_CHANGE_REQUEST: (state, payload) => {
            state.agent_change_request = payload
        },
        SET_NOTES: (state, payload) => {
            state.notes = payload
        },
        SET_ONLINE_INQUIRY: (state, payload) => {
            state.online_inquiry = payload
        },
    }, actions: {
        GET_APPLICATION_COUNT: (context) => {
            applicationService.getApplicationCount().then(response => {
                let application = response.data
                context.commit('SET_VISA_APPLICATION_COUNT', application.visa_application_count ? application.visa_application_count : 0);
                context.commit('SET_ACADEMIC_APPLICATION_COUNT', application.academic_application_count ? application.academic_application_count : 0);
                context.commit('SET_ACADEMIC_APPLICATION_OPEN_COUNT', application.academic_application_open_count ? application.academic_application_open_count : 0);
                context.commit('SET_ACADEMIC_APPLICATION_NOT_OPEN_COUNT', application.academic_application_not_open_count ? application.academic_application_not_open_count : 0);
                context.commit('SET_TOTAL_APPLICANTS', application.total_applicants ? application.total_applicants : 0);
                context.commit('SET_ACADEMIC_APPLICATION_DRAFT_COUNT', application.draft_count ? application.draft_count : 0);
                context.commit('SET_ACADEMIC_APPLICATION_SUBMIT_COUNT', application.submited_count ? application.submited_count : 0);
                context.commit('SET_ACADEMIC_APPLICATION_TOTAL_SUBMIT_COUNT', application.total_submited_count ? application.total_submited_count : 0);
                context.commit('SET_ACADEMIC_APPLICATION_VALID_OFFERS_COUNT', application.valid_offers_count ? application.valid_offers_count : 0);
                context.commit('SET_ACADEMIC_APPLICATION_OFFERS_COUNT', application.offers_count ? application.offers_count : 0);
                context.commit('SET_ACADEMIC_APPLICATION_DEFERRED_COUNT', application.deferred_count ? application.deferred_count : 0);
                context.commit('SET_ACADEMIC_APPLICATION_REFUND_COUNT', application.refund_count ? application.refund_count : 0);
                context.commit('SET_ACADEMIC_APPLICATION_ARCHIVED_COUNT', application.archived_count ? application.archived_count : 0);
                context.commit('SET_ACADEMIC_APPLICATION_VISA_READY_COUNT', application.visa_ready_count ? application.visa_ready_count : 0);
                context.commit('SET_ACADEMIC_APPLICATION_VISA_GRANTED_COUNT', application.visa_granted_count ? application.visa_granted_count : 0);
                context.commit('SET_ACADEMIC_APPLICATION_PENDING_PAYMENTS_COUNT', application.pending_payments_count ? application.pending_payments_count : 0);
            }).catch(error => {
                // context.commit('SET_VISA_APPLICATION_COUNT', 0)
                // context.commit('SET_ACADEMIC_APPLICATION_COUNT', 0)
            })
        },
        GET_APPLICATION_UPDATE_REQUEST_COUNT: (context) => {
            dashboard.getDashboardStats().then(response => {
                context.commit('SET_UPDATE_REQUEST_APPLICATION_DEFERRED', response.data.application_deferred ? response.data.application_deferred : 0)
                context.commit('SET_UPDATE_REQUEST_APPLICATION_REFUND', response.data.application_refund ? response.data.application_refund : 0)
                context.commit('SET_AGENT_CHANGE_REQUEST', response.data.agent_change_requests ? response.data.agent_change_requests : 0)
                context.commit('SET_NOTES', response.data.noteCount ? response.data.noteCount : 0)
                context.commit('SET_ONLINE_INQUIRY', response.data.contactUsCount ? response.data.contactUsCount : 0)
            }).catch(() => {});
        },
    }
}

export default stats;