import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { API_URL } from "@/common/config";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_URL;
        Vue.axios.defaults.withCredentials = true;
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
         Vue.axios.defaults.headers.common[
           "Content-Type"
         ] ='application/json';
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch(error => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource, slug = "") {
        let url = `${resource}${(slug)?"/"+slug:""}`
        return Vue.axios.get(`${url}`).catch(error => {
            if(error.response.status== 403 ){
                this.unauthorized();
            }
            throw error.response.data;
        });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        return Vue.axios.post(`${resource}`, params).catch(error => {
            if (error.response.status == 401 || error.response.status == 403  ) {
              this.unauthorized();
            }
            throw error.response.data;
        });
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return Vue.axios.put(`${resource}`, params).catch(error => {
            if (error.response.status == 401 || error.response.status == 403  ) {
                this.unauthorized();
            }
            throw error.response.data;
        });
    },
    unauthorized(){

        window.location.href='/#/unauthorized';
    },

    patch(resource, params) {
        return Vue.axios.patch(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return Vue.axios.delete(resource).catch(error => {
            // console.log(error);
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
};

export default ApiService;
