import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CountryService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/country';
    }

    getAllCountry(data) {
        let url = `${this.#api}`;
        let param = {
            params: data
        }
        return apiService.query(url, param)
    }

    toggleOperating(countryId) {
        let url = `${this.#api}/${countryId}/operating/toggle`;
        return apiService.get(url)
    }

    toggleEligibleForApply(countryId) {
        let url = `${this.#api}/${countryId}/eligible-for-apply/toggle`;
        return apiService.get(url)
    }

    toggleIsActive(countryId) {
        let url = `${this.#api}/${countryId}/active/toggle`;
        return apiService.get(url)
    }

    getById(countryId) {
        let url = `${this.#api}/${countryId}`;
        return apiService.get(url)
    }

    update(countryId, data) {
        let url = `${this.#api}/${countryId}`;
        return apiService.post(url,data)
    }

    getSelectedCurrencies() {
        let url = `${this.#api}/selected/currencies`;
        return apiService.get(url)
    }

}