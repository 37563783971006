import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import UserPermission from "./userpermission";
import SiteSetting from "./site-setting";
import discussion from "./discussion.module";
import stats from "@/core/services/store/stats";

Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        auth,
        htmlClass,
        config,
        breadcrumbs,
        UserPermission,
        SiteSetting,
        discussion,
        stats
    }
});
