import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class InstitutionService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/institution';
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    all() {
        let url = `${this.#api}/get/all`
        return apiService.get(url)
    }

    search(data = '') {
        let url = `${this.#api}/get/search`
        if(data) {
            url = `${url}?title=${data}`;
        }
        return apiService.get(url)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    update(id, data) {
        let url = `${this.#api}/${id}/update`
        return apiService.post(url, data)
    }

    delete(examOptionId) {
        let url = `${this.#api}/${examOptionId}`
        return apiService.delete(url)
    }

    saveCropperImage(id, data) {
        let url = `${this.#api}/${id}/save-image`
        return apiService.post(url, data)
    }

    getByCountry(countryId){
        let url = `${this.#api}/${countryId}/country`
        return apiService.get(url)
    }

    countInstitution(){
        let url = `${this.#api}/get/count-institution`
        return apiService.get(url)
    }
}
