import ApplicationDiscussionCategoryService
    from "@/services/application/discussion/category/ApplicationDiscussionCategoryService";

const discussionCategory = new ApplicationDiscussionCategoryService();
const Discussion = {

    state: {
        categories: [],
        totalCategoryCount: 0,
        category: {},
    },
    getters: {
        getCategories(state) {
            return state.categories;
        },
        getCategory(state) {
            return state.category;
        },
        totalCategory(state) {
            return state.totalCategoryCount;
        },
    },
    actions: {
        GET_DISCUSSION_CATEGORIES: (context, payload) => {
            discussionCategory.getCategoryMenu().then(response => {
                context.commit('SET_DISCUSSION_CATEGORIES', response.data.discussionCategories);
                context.commit('SET_DISCUSSION_CATEGORY_COUNT', response.data.discussionCategories);
                if(payload != undefined && payload != 'all'){
                    context.commit('SET_DISCUSSION_CATEGORY', payload);
                }
            }).catch(() => {})
        }
    },
    mutations: {
        SET_DISCUSSION_CATEGORIES: (state, payload) => {
            state.categories = payload
        },
        SET_DISCUSSION_CATEGORY: (state, payload) => {
            // state.category = payload;
            let categories = state.categories;
            categories.forEach(category => {
                if (category.id != 'all' && category.id == payload)
                    state.category = category;
            });
        },
        SET_DISCUSSION_CATEGORY_COUNT: (state, payload) => {
            let categories = payload;
            categories.forEach(category => {

                if(category.name == 'All') {
                    state.totalCategoryCount = category.discussion_count;
                }
            });
        }
    }
}
export default Discussion;