import {mapActions} from "vuex";
import CountryService from "@/services/country/CountryService";
import StateService from "@/services/country/state/StateService";
import CityService from "@/services/country/city/CityService";
import UserService from "@/services/user/UserService";
import InstitutionService from "@/services/academic/institution/InstitutionService";
import AcademicProgramService from "@/services/academic/program/AcademicProgramService";
import EducationLevelService from "@/services/academic/education-level/EducationLevelService";
import VisaApplicationStatusService from "@/services/visa/application-status/VisaApplicationStatusService";
import BranchService from "@/services/branch/BranchService";


const user = new UserService();
const cityService = new CityService();
const country = new CountryService();
const state = new StateService();
const institutionService = new InstitutionService();
const academicProgramService = new AcademicProgramService();
const educationLevelService = new EducationLevelService();
const statusService = new VisaApplicationStatusService();
const branch = new BranchService();

const GeneralMixin = {
    data() {
        return {
            countries: [],
            ourBranches: [],
            stateLoading: false,
            cityLoading: false,
            location: {},
            states: [],
            city: [],
            applicant_users: [],
            default_country_code: null,
            countryId: null,
            admin_users: [],
            programs: [],
            education_levels: [],
            countrySearch: {'is_operating_country': 1},
            applicant_search: {
                info: '',
                is_active: '',
                type: '',
                mobile: '',
                is_student: '1'
            },
        }
    },
    computed: {
       currentUser() {
           return this.$store.getters.currentUser;
       },
        mySiteSetting() {
           return this.$store.getters.getMySiteSetting;
       },
    },
    methods: {
        ...mapActions([
            "GET_USER_PERMISSIONS",
        ]),

        checkIsAccessible(menu, subMenu = '') {
            let permissions = this.$store.getters.getPermissions;
            if (permissions[menu] && permissions[menu].length > 0) {
                return (permissions[menu].indexOf(subMenu) != -1);
            } else
                return false;

        },

        getAllBranches(){
            branch.all().then((response) => {
                this.ourBranches = response.data.branches;
            }).catch(() => {});
        },

        getAllApplicantUsers() {
            this.loading = true;
            user
                .paginate(this.applicant_search, this.page)
                .then(response => {
                    this.applicant_users = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.loading = false;
                })
                .catch(() => {
                    this.$snotify.error("Oops something went wrong");
                });
        },

        changeCountry() {
            this.default_country_code = this.country.iso_2;
            this.countryId = this.country.id;
            this.getAllState()
        },

        getAllState() {
            this.stateLoading = true;
            state
                .all(this.countryId)
                .then(response => {
                    this.states = response.data.data;
                })
                .catch((err) => {
                }).finally(() => {
                this.stateLoading = false;
            });
        },

        getAllCity(state_id) {
            this.cityLoading = true;
            cityService
                .all(state_id)
                .then(response => {
                    this.cities = response.data.data;
                    this.cityLoading = false;
                })
                .catch((err) => {
                });
        },

        getInstitution(countryId) {
            institutionService.getByCountry(countryId).then(response => {
                this.institutions = response.data.institutions;
            })
        },

        getProgramByInstitution(institutionId, educationLevelId) {
            academicProgramService.getByInstitution(institutionId, educationLevelId).then(response => {
                this.programs = response.data.programs;
            })
        },

        getEducationLevel() {
            educationLevelService.all().then(response => {
                this.education_levels = response.data.educationLevels;
            })
        },

        getVisaApplicationStatus(countryId = null, applicationType = null) {
            if (!applicationType || !countryId)
                return
            statusService.getByCountry(countryId, applicationType).then(response => {
                this.application_statuses = response.data.data;
            })
        },

        getCountries(){
            country
                .getAllCountry({'is_operating_country':1})
                .then((response) => {

                    this.countries = response.data.data;
                })
                .catch(() => {
                })
        },

        displayMessage(type, msg = null) {
            let defaultSuccessMessage = "Request has been processed successfully";
            let defaultErrorMessage = "Ops something went wrong please try again later";
            if (type == "success") {
                this.$snotify.success(msg ? msg : defaultSuccessMessage)
            } else {
                this.$snotify.error(msg ? msg : defaultErrorMessage)
            }
        }
    }
};

export default {
    install(Vue) {
        Vue.mixin(GeneralMixin)
    }
}


