import SiteSettingService from "@/services/cms/site-setting/SiteSettingService";

const settingService = new SiteSettingService();
const SiteSetting = {

    state: {
        siteSetting: {},
    },
    getters: {
        getMySiteSetting(state) {
            return state.siteSetting;
        },
    },
    actions: {
        GET_SITE_SETTING: (context, payload) => {
            settingService.show().then(response => {
                if(response.data) {
                    context.commit('SET_SITE_SETTING', response.data);
                } else {
                    context.commit('SET_SITE_SETTING', {});
                }
            }).catch(() => {
                context.commit('SET_SITE_SETTING', {});
            })
        }
    },
    mutations: {
        SET_SITE_SETTING: (state, payload) => {
            state.siteSetting = payload
        },
    }
    ,
}
export default SiteSetting;