import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js';
const apiService = Apiservice;

export default class DashboardService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/dashboard';
    }
    getInfo(filter =null){
        let url = `${this.#api}/${filter}`;
        return apiService.get(url)
    }
    getDashboardStats(){
        let url = `${this.#api}/stat`;
        return apiService.get(url)
    }


}