import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class StateService  {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/state';
    }

    paginate(data, index = null) {

        let url = this.#api;
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    get(id) {
        let url = `${this.#api}/${id}/details`
        return apiService.get(url)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    all(country_id){
        let url = `${this.#api}/get/${country_id}/all`;
        return apiService.get(url)
    }

}
